import Rodal from 'rodal';
// include styles
import 'rodal/lib/rodal.css';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';

export const Ralert = ({ msgloadalert, msgtypeloadalert, loadalert, closemodel }) => {
	const { darkModeStatus, themeStatus } = useDarkMode();
	//const capitalizedText = text => text.charAt(0).toUpperCase() + text.slice(1);
	const capitalizedText = msgtypeloadalert
		? msgtypeloadalert[0].toUpperCase() + msgtypeloadalert.substring(1)
		: '';
	const capitalizedTextbody = msgloadalert
		? msgloadalert[0].toUpperCase() + msgloadalert.substring(1)
		: '';
	//console.log("Loadalert",msgtypeloadalert)
	let alerttype = 'Done';
	if (msgtypeloadalert === 'error' || msgtypeloadalert === 'Error') {
		alerttype = 'ErrorOutline';
	} else if (msgtypeloadalert === 'Success' || msgtypeloadalert === 'success') {
		alerttype = 'CheckCircleOutline';
	}

	let altcolor = 'primary';
	if (msgtypeloadalert === 'Error' || msgtypeloadalert === 'error') {
		altcolor = 'danger';
	} else if (msgtypeloadalert === 'Success' || msgtypeloadalert === 'success') {
		altcolor = 'primary';
		//altcolor = 'danger';
	}

	return (
		<Rodal
			// visible={isempty}
			visible={loadalert}
			onClose={closemodel}
			// style={{
			// 	border: 'none !important',
			// 	padding: '0 !important',
			// 	// backgroundColor: '#242731',
			// }}
		>
			<div
				style={{
					height: '244px',
					width: '404px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					backgroundColor: themeStatus === 'dark' ? '#141414' : '#f5f5f7',
					border: themeStatus === 'dark' ? '4px solid #f5f5f7' : '4px solid #141414',
					// margin: -17,
					borderRadius:'8px',
					// backgroundColor: '#242731',
				}}>
				<div
					style={{
						//backgroundColor:"red",
						display: 'flex',
						paddingTop: '30px',
						width: '100%',
						alignItems: 'center',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}>
					{/* {capitalizedText({msgtypeloadalert})} */}
					{/* {capitalizedText} */}

					<Icon icon={alerttype} color={altcolor} size='6x' />
				</div>
				<center>
				<div
					style={{
						// color: 'black',
						color: themeStatus === 'dark' ? '#e5e5e5' : '#141414',
						fontSize: '15px',
						display: 'flex',
						width: '100%',
						alignItems: 'center',
						flexDirection: 'column',
						justifyContent: 'space-between',
						fontWeight: 'bold',
					}}>
					{msgloadalert}
				</div>
				</center>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						gap: '25px',
						paddingBottom: '10px',
					}}>
					{/* <button
                    style={{
                        width: '100px',
                        backgroundColor: 'skyblue',
                        borderRadius: '7px',
                        color: 'white',
                        outline: 'none',
                        border: 'none',
                    }}
                    // onClick={updaterefpay}
                    onClick={rejectrefpay}>
                    Yes,Reject
                </button> */}

					{/* <button
                    style={{
                        width: '100px',
                        //backgroundColor:"red",
                        borderRadius: '7px',
                        color: 'black',
                        outline: 'none',
                        border: '2px solid skyblue',
                    }}
                    onClick={closemodel}>
                    close
                </button> */}
					{altcolor === 'danger' ? (
						<Button
							// icon='PersonAdd'
							style={{
								width: '100px',
								height: '40px',
								borderRadius: '7px',
								fontSize: '16px',
								backgroundColor: '#e6e6e6',
								color: '#000000',
								//color: 'primary',
								//outline: 'none',
								border: '3px solid #cecfd3',
							}}
							// color={altcolor}
							isLight
							onClick={closemodel}>
							Close
						</Button>
					) : (
						<Button
							// icon='PersonAdd'
							style={{
								width: '100px',
								height: '40px',
								borderRadius: '7px',
								fontSize: '16px',
								backgroundColor: '#e6e6e6',
								color: '#000000',
								//color: 'primary',
								//outline: 'none',
								border: '3px solid #cecfd3',
							}}
							// color={altcolor}
							isLight
							onClick={closemodel}>
							Close
						</Button>
					)}
				</div>
			</div>
		</Rodal>
	);
};
